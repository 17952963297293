<template>
  <BaseModal :show="show">
    <template slot="content">
      <AppIcon name="checkmark_filled" size="70" class="text-success"></AppIcon>
      <h5 class="mt-2">SUBMITTED!</h5>
      <p class="mt-2">Your request has been submitted! Thank you.</p>
    </template>
    <div slot="action-btn" class="mt-2">
      <AppBtn
        outline
        color="secondary"
        class="ml-auto mr-auto d-block"
        @click="$emit('close')"
        :loading="loading"
      >
        close
      </AppBtn>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/Partial/Modals/BaseModal.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false
    }
  },
  components: {
    BaseModal,
    AppBtn,
    AppIcon,
  },
};
</script>
