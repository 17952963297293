<template>
  <div class="landing h-100 overflow-auto d-flex">
    <div
      class="m-auto p-md-3 d-md-flex align-items-center justify-content-center"
    >
      <div class="landing-container row">
        <!-- Hero Column -->
        <div
          class="
            col-12 col-md-7
            d-flex
            align-items-start align-items-md-center align-items-xl-start
          "
        >
          <div class="p-3 pt-xl-5">
            <img src="@/assets/pocketyard_logo_black.svg" width="33%" />
            <h1 class="landing-container-headline mt-3">
              With pocketyard, your construction supply buying is now easy
              lifting.
            </h1>
            <p class="landing-container-description mt-3">
              You've got schedules, and budgets to keep. When your team is on
              pocketyard, you've got everything you need to keep construction
              moving. All in your pocket.
            </p>
          </div>
        </div>
        <!-- Registration Form -->
        <div class="col-12 col-md-5">
          <div class="mb-5 mb-md-0 pb-5 p-1">
            <AppInputForm
              v-model="formValid"
              dark
              class="p-3 p-xxl-4 bg-black-light-1 shadow-80 text-white rounded"
              @validate="registerAdmin"
              v-slot="{ validate }"
            >
              <h5
                class="
                  text-md-headline-2 text-md-center text-uppercase
                  font-weight-4
                "
              >
                EVERYTHING. EASIER.
              </h5>
              <p class="d-none d-md-block text-center font-weight-3">
                We're launching soon! Stay in the loop by adding your info below
                to join our waiting list. Thank you!
              </p>
              <div class="mt-4 mt-xxl-5 d-flex">
                <AppInput
                  v-model="form.first_name"
                  label="first name"
                  class="w-50 mr-2"
                  :validations="[validation.required()]"
                  :validate="validate"
                ></AppInput>
                <AppInput
                  v-model="form.last_name"
                  label="last name"
                  class="w-50"
                  :validations="[validation.required()]"
                  :validate="validate"
                ></AppInput>
              </div>
              <AppInput
                v-model="form.email"
                label="email"
                class="mt-1"
                :validations="[validation.required(), validation.email()]"
                :validate="validate"
              ></AppInput>
              <AppInput
                v-model="form.mobile_number"
                label="mobile number"
                class="mt-1"
                :validations="[
                  validation.required(),
                  validation.minLength(8),
                  validation.usPhoneNum(),
                ]"
                :validate="validate"
              ></AppInput>
              <AppInput
                v-model="form.company"
                label="company name"
                class="mt-1"
                :validations="[validation.required()]"
                :validate="validate"
              ></AppInput>

              <AppInputCheckbox
                v-model="form.get_marketing_update_email"
                class="mt-1"
                label="Receive updates from pocketyard"
              ></AppInputCheckbox>

              <AppBtn
                type="submit"
                class="mt-4 w-100"
                :color="sent ? 'info-light-5 text-info' : 'primary'"
                :loading="loading"
                :disabled="sent"
              >
                {{ sent ? 'SUBMITTED' : 'SUBMIT' }}
              </AppBtn>

              <p class="mt-4 text-center mb-0 text-small">
                By clicking the "Submit" button, you agree to pocketyard's
                <router-link :to="{ name: 'TermsAndConditions' }">
                  Terms of Use
                </router-link>
                and
                <router-link :to="{ name: 'PrivacyPolicy' }">
                  Privacy Policy
                </router-link>
                .
              </p>
            </AppInputForm>
          </div>
        </div>
      </div>
    </div>
    <SubmitSuccessModal :show="showSubmittedModal" @close="handleClose" />
  </div>
</template>

<script>
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppInput from '../../shared/elements/AppInput.vue';
import AppInputCheckbox from '../../shared/elements/AppInputCheckbox.vue';
import Authentication from '@/shared/api/Authentication';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import validation from '@/shared/utils/validation';
import AppInputForm from '../../shared/elements/AppInputForm.vue';
import SubmitSuccessModal from './SubmitSuccessModal.vue';
import { mapMutations } from 'vuex';

const INITIAL_FORM_STATE = {
  first_name: '',
  last_name: '',
  email: '',
  mobile_number: '',
  company: '',
  get_marketing_update_email: false,
  address: 'N/A',
  city: 'N/A',
  state: 'N/A',
  zip_code: 'N/A',
};

export default {
  name: 'Landing',

  mixins: [_appApiHelper],

  components: {
    AppBtn,
    AppInput,
    AppInputCheckbox,
    AppInputForm,
    SubmitSuccessModal,
  },

  data() {
    return {
      showSubmittedModal: false,
      validation,
      sent: false,
      formValid: false,
      form: { ...INITIAL_FORM_STATE },
    };
  },

  mounted() {
    this.setPreventSuccess(true);
  },
  methods: {
    ...mapMutations('api', {
      setPreventSuccess: 'PREVENT_SUCCESS_DEFAULT',
    }),
    handleClose() {
      this.showSubmittedModal = false;
      this.sent = false;
    },
    async registerAdmin() {
      this.setPreventSuccess(true);
      if (!this.formValid) {
        return false;
      }
      const { get_marketing_update_email } = this.form;
      const form = {
        ...this.form,
        get_marketing_update_email: +get_marketing_update_email,
      };
      const response = await Authentication.register(form);
      if (response.status === 200) {
        this.showSubmittedModal = true;
        this.sent = true;
        this.form = { ...INITIAL_FORM_STATE };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';
.landing {
  background: linear-gradient(50deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(~@/assets/Landing/background.png);
  background-blend-mode: darken;
  -webkit-background-blend-mode: darken;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  @include media-md('below') {
    background-size: 500%;
    background-position: 51% 90%;
    overflow-x: hidden !important;
  }
  &-container {
    max-width: 1400px;

    &-headline {
      font-style: normal;
      font-weight: 500;
      font-size: 56px;
      line-height: 84px;
      letter-spacing: 0.8px;
      text-transform: uppercase;

      @include media-xxl('below') {
        font-size: 32px;
        line-height: 48px;
      }

      @include media-md('below') {
        font-size: 20px;
        line-height: 30px;
      }
    }

    &-description {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;

      @include media-xxl('below') {
        font-size: 16px;
        line-height: 24px;
      }

      @include media-md('below') {
        font-weight: 300;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
}
</style>
