<template>
  <AppModal v-model="show" width="550px">
    <div
      class="p-4 d-flex flex-column align-items-center justify-content-center"
    >
      <slot>
        <slot name="content"></slot>
        <!-- Action Buttons -->
        <slot name="action-btn"></slot>
      </slot>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/shared/elements/AppModal.vue';

export default {
  name: 'ActionModal',
  components: { AppModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  width: 550px;
}
</style>
